class NavHandler {
  constructor() {
    this.button = document.querySelector(".header-mobile__button")
    this.nav = document.querySelector(".header-mobile__nav")
    this.layer = document.querySelector(".layer")

    document.body.addEventListener("click", ev => {
      if (ev.target.classList == this.button.classList) {
        if (ev.target.classList.contains("header-mobile__button--active")) {
          ev.target.classList.remove("header-mobile__button--active")
          this.nav.classList.remove("header-mobile__nav--active")
          this.layer.classList.remove("layer--active")
          document.documentElement.removeAttribute("style")
        } else {
          this.nav.classList.add("header-mobile__nav--active")
          ev.target.classList.add("header-mobile__button--active")
          this.layer.classList.add("layer--active")
          document.documentElement.style.overflow = "hidden"
        }
      }
      if (ev.target == this.layer) {
        this.nav.classList.remove("header-mobile__nav--active")
        this.layer.classList.remove('layer--active')
        this.button.classList.remove("header-mobile__button--active")
        document.documentElement.removeAttribute("style")
      }
    })
  }
}

export default NavHandler
