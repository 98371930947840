class LanguageHandler {
  constructor() {
    this.button = document.querySelector(".header-desktop__language")
    this.subnav = document.querySelector(".header-desktop__subnav")
    this.layer = document.querySelector(".layer")
    this.active = "header-desktop__subnav--active"

    this.mobile = document.querySelector(".header-mobile__language")
    this.mobilenav = document.querySelector(".header-mobile__language-nav")
    document.body.addEventListener("click", ev => {
      if (!this.button) return
      const isButton = ev.target === this.button || this.button.contains(ev.target)
      const isInsideSubnav = this.subnav.contains(ev.target)

      if (isButton) {
        this.subnav.classList.toggle(this.active)
        this.layer.classList.toggle("layer--active")
      } else if (!isInsideSubnav) {
        this.subnav.classList.remove(this.active)
        this.layer.classList.remove("layer--active")

        if (ev.target.classList == this.mobile.classList) {
          if (this.mobilenav.classList.contains("header-mobile__language-nav--active")) {
            this.mobilenav.classList.remove("header-mobile__language-nav--active")
            this.layer.classList.remove("layer--active")
          } else {
            this.mobilenav.classList.add("header-mobile__language-nav--active")
            this.layer.classList.add("layer--active")
          }
        } else if (ev.target === this.layer && this.mobilenav.classList.contains("header-mobile__language-nav--active")) {
          this.mobilenav.classList.remove("header-mobile__language-nav--active")
          this.layer.classList.remove("layer--active")
        }

        if (document.querySelector(".header-mobile__button--active")) {
          document.querySelector(".header-mobile__button--active").classList.remove("header-mobile__button--active")
          document.querySelector(".header-mobile__nav--active").classList.remove("header-mobile__nav--active")
        }
      }
    })
  }
}

export default LanguageHandler
