/**
 * Example to get you started below:
 */
import InfocardShowcaseParallax from './modules/InfocardShowcaseParallax'
import LanguageHandler from './modules/LanguageHandler'
import NavHandler from './modules/NavHandler'

document.addEventListener('DOMContentLoaded', () => {
  // Add all your JS modules here:
  let languageHandler = new LanguageHandler()
  let navHandler = new NavHandler()
  let infocardShowcaseParallax = new InfocardShowcaseParallax()
})
