import SimpleParallax from "simple-parallax-js/vanilla"

class InfocardShowcaseParallax {
  constructor() {
    this.smoothScrollY = window.scrollY
    this.lerpFactor = 0.08
    this.wrappers = document.querySelectorAll(".infocard-showcase__wrapper")
    this.images = document.querySelectorAll(".infocard-showcase__background")

    // this.updateScroll = this.updateScroll.bind(this)
    this.init()
  }

  lerp(start, end, t) {
    return start * (1 - t) + end * t
  }

  updateScroll() {
    this.smoothScrollY = this.lerp(this.smoothScrollY, window.scrollY, this.lerpFactor)

    this.wrappers.forEach(wrapper => {
      const rect = wrapper.getBoundingClientRect()
      const center = window.innerHeight / 2
      const distance = rect.top + rect.height / 2 - center

      const maxDistance = window.innerHeight / 1.2
      let opacity = 1 - Math.abs(distance / maxDistance)
      opacity = Math.max(0, Math.min(1, opacity))

      const overshoot = distance * 0.5
      wrapper.style.opacity = opacity
      wrapper.style.transform = `translateY(${overshoot}px) scale(${1.1 + opacity * 0.1})`
    })

    requestAnimationFrame(this.updateScroll)
  }

  init() {
    // this.updateScroll();
    if (window.innerWidth > 576) {
      if (this.images.length) {
        this.images = Array.from(this.images);
        this.images.forEach(image => {
          new SimpleParallax(image, {
            scale: 1.45,
            overflow: false,
            // maxTransition: 25,
          });
        });
      }
    }
  }
}

export default InfocardShowcaseParallax
